<template>
  <div>
    <!-- custom dialog -->
    <v-dialog v-model="customDialogObj.show" :width="customDialogObj.width || 500"
      :persistent="customDialogObj.persistent === false ? false : true">
      <v-card :loading="customDialogObj.loading">
        <v-card-title :class="['pt-4 pb-3 px-4 primary white--text', (customDialogObj.title && customDialogObj.title.class) ? (customDialogObj.title.class({ dialog: customDialogObj })) : '']">
          <span class="subtitle-1 font-weight-bold"> {{ (customDialogObj.title && customDialogObj.title.label) ? customDialogObj.title.label : '' }} </span>
          <v-spacer></v-spacer>
          <v-icon dark v-if="customDialogObj.title && customDialogObj.title.icon"> {{ customDialogObj.title.icon }} </v-icon>
        </v-card-title>
        <v-card-text class="py-4 px-4 text-left">
          <span class="body-2 font-weight-medium"> {{ customDialogObj.body ? customDialogObj.body({ dialog: customDialogObj }) : '' }} </span>
        </v-card-text>
        <v-divider v-if="customDialogObj.actions && customDialogObj.actions.length"></v-divider>
        <v-card-actions v-if="customDialogObj.actions && customDialogObj.actions.length">
          <v-spacer></v-spacer>
          <template v-for="(btn, index) in customDialogObj.actions">
            <v-btn
              v-if="btn.show ? btn.show({ dialog: customDialogObj, button: btn }) : true"
              :color="btn.color({ dialog: customDialogObj, button: btn })" :loading="btn.loading" :outlined="btn.outlined"
              :disabled="btn.disabled"
              :key="index"
              class="text-capitalize"
              @click="btn.click && btn.click({ dialog: customDialogObj, button: btn }); $forceUpdate()"
            >
              {{ btn.label }}
            </v-btn>
          </template>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar v-model="snackbarObj.show" :color="snackbarObj.color">
      <v-icon size="18" class="mr-2 mt-n1" v-if="snackbarObj.icon"> {{ snackbarObj.icon }} </v-icon>
      <span v-html="snackbarObj.message"></span>
      <template #action>
        <v-btn icon text @click="snackbarObj.show = false" small>
          <v-icon small> mdi-close-circle </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- sidebar -->
    <side-navbar></side-navbar>
  </div>
</template>
<script>
import eventBus from '../main'
export default {
  data () {
    return {
      // custom dialog
      customDialogObj: {
        close: () => this.customDialogObj.show = false,
        loading: false
      },
      // snackbar
      snackbarObj: {
        show: false
      }
    }
  },
  components: {
    'side-navbar': () => import('@/components/SideNavBar.vue')
  },
  mounted () {
    // custom dialog
    this.$root.$on('customDialog', ({ model, payload }) => {
      this.customDialogObj = Object.assign(model, { ...this.customDialogObj, payload, show: true })
    })
    // delete handler
    this.$root.$on('delete', (payload) => {
      this.customDialogObj = {
        width: 400,
        title: {
          label: 'Confirm delete?',
          icon: 'mdi-alert',
          class: () => 'error darken-1 white--text'
        },
        body: () => 'Are you sure to delete the item?',
        actions: [{
          label: 'Yes',
          color: () => 'error darken-1',
          click: ({ dialog, button }) => {
            const { payload } = dialog
            if (payload.module) {
              button.loading = true
              this.$_execute('delete', `${payload.module}/${payload._id}`)
                .then(() => payload.onResolve && payload.onResolve())
                .finally(() => {
                  button.loading = false
                  dialog.close()
                  payload.onComplete && payload.onComplete()
                })
            } else {
              if (payload.onResolve) payload.onResolve()
              dialog.close()
            }
          }
        }, {
          label: 'No',
          color: () => 'primary',
          outlined: true,
          click: ({ dialog, button }) => {
            payload.onReject ? payload.onReject({ dialog, button }) : dialog.close()
          }
        }],
        ...this.customDialogObj,
        show: true,
        payload
      }
    })
    // snackbar
    this.$root.$on('snackbar', (payload) => {
      payload.icon = (payload.color === 'error') ? 'mdi-alert' : ''
      this.snackbarObj = { show: true, ...payload }
    })
    eventBus.$on('snackbar', (payload) => {
      payload.icon = (payload.color === 'error') ? 'mdi-alert' : ''
      this.snackbarObj = { show: true, ...payload }
    })
  },
  beforeDestroy () {
    this.$root.$off('customDialog')
    this.$root.$off('delete')
    this.$root.$off('snackbar')
    eventBus.$off('snackbar')
  }
}
</script>